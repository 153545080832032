<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-size-100">
        <tgl-breadcrumbs :items="breadcrumbs" />
        <div class="row w-100 justify-end mb-10 mt-10">
          <v-btn color="primary" @click="enableEdit = true">Enable edit</v-btn>
          <v-btn
            color="primary"
            class="mr-16 ml-5"
            @click="showPasswordModal = true"
            >Change password</v-btn
          >
        </div>
        <account
          @user="setUser"
          :editable="enableEdit"
          @disableEditMode="enableEdit = false"
          :changePassword="showPasswordModal"
          @close="showPasswordModal = false"
        ></account>
      </div>
    </div>
  </div>
</template>

<script>
import Account from "@/components/Account/Account.vue";

import { mapGetters } from "vuex";
import TglBreadcrumbs from "@/components/UI/TglBreadcrumbs.vue";
export default {
  components: {
    Account,
    TglBreadcrumbs,
  },
  computed: {
    ...mapGetters(["getTheme"]),
    breadcrumbs() {
      return [
        {
          label: "Tablero",
          disabled: false,
          to: { name: "dashboard" },
        },
        {
          label: "Mi información",
          disabled: true,
          to: {
            name: "dashbord",
          },
        },
      ];
    },
  },
  data() {
    return {
      user: null,
      enableEdit: false,
      showPasswordModal: false,
    };
  },
  methods: {
    setUser(user) {
      this.user = user;
    },
  },
};
</script>
